@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');


/* Poppins font */
/*@font-face {
  font-family: 'Nunito';
  src: url('Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: extralight;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: semibold;
}

@font-face {
  font-family: 'Nunito';
  src: url('Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: thin;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: extralight;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: semibold;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}
*/
/*--------------------------------------------------------------
# General Start
--------------------------------------------------------------*/

a {
  text-decoration: none !important;

}

a:hover {
  text-decoration: none !important;
  color: white;
}

:root {
  --gray: rgb(158, 154, 154);
  --lighter-gray: rgb(237, 230, 230);
  --light-gray: rgb(209, 203, 203);
  --dim-gray: #696969;
  --persian-green: #2A9D8F;
  --dark-moderate-blue: #40768C;
  --light-grayish-orange: #FDF1E7;
  --white: white;
  --dark-shade-magenta: #3E2943;
  --dark-magenta: hsl(287, 31%, 11%);
  --black-shade: rgba(58, 55, 55, 0.7);
  --light-yellow-shade: #E5B94D;
  --light-shade-red-orange: #E66B4C;
  --light-shade-cyan: #EBFAF8;
  --dark-cyan-blue: #003D69;
  --black: black;
  --alice-blue: #F0F4F6;
  --cyan-shade: #EBF6F7;
  --light-cyan-blue: rgba(51, 82, 110, 0.15);
  --light-green: #00D14A;
  --violet: #6F3EFF;

  --color-default: #1a1f24;
  --color-default-rgb: 26, 31, 36;

  /* Defult links color */
  --color-links: #0ea2bd;
  --color-links-hover: #1ec3e0;

  /* Primay colors */
  --color-primary: #0ea2bd;
  --color-primary-light: #1ec3e0;
  --color-primary-dark: #0189a1;

  --color-primary-rgb: 14, 162, 189;
  --color-primary-light-rgb: 30, 195, 224;
  --color-primary-dark-rgb: 1, 137, 161;

  /* Secondary colors */
  --color-secondary: #485664;
  --color-secondary-light: #8f9fae;
  --color-secondary-dark: #3a4753;

  --color-secondary-rgb: 72, 86, 100;
  --color-secondary-light-rgb: 143, 159, 174;
  --color-secondary-dark-rgb: 58, 71, 83;

  /* General colors */
  --color-blue: #0d6efd;
  --color-blue-rgb: 13, 110, 253;

  --color-indigo: #6610f2;
  --color-indigo-rgb: 102, 16, 242;

  --color-purple: #6f42c1;
  --color-purple-rgb: 111, 66, 193;

  --color-pink: #f3268c;
  --color-pink-rgb: 243, 38, 140;

  --color-red: #df1529;
  --color-red-rgb: 223, 21, 4;

  --color-orange: #fd7e14;
  --color-orange-rgb: 253, 126, 20;

  --color-yellow: #ffc107;
  --color-yellow-rgb: 255, 193, 7;

  --color-green: #059652;
  --color-green-rgb: 5, 150, 82;

  --color-teal: #20c997;
  --color-teal-rgb: 32, 201, 151;

  --color-cyan: #0dcaf0;
  --color-cyan-rgb: 13, 202, 240;

  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;

  --color-gray: #6c757d;
  --color-gray-rgb: 108, 117, 125;

  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;
}


.poppins-font {
  font-family: 'Poppins';
}

.nunito-font {
  font-family: 'Nunito';
}

/*--------------------------------------------------------------
# General Ends
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# MainScreen Front Page Starts
--------------------------------------------------------------*/
.dissolve {
  transition: opacity 1s; /* Adjust the transition time to match the setTimeout in the React component */
  opacity: 0;
}


#navbar {
  background-color: var(--black);
  padding: 10px 40px;
}

.feature_img {
  width: 50%;
}

.front-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  background-color: var(--black);
}

.front-page-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 0;
  background-color: var(--black);
}

#left-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
  height: 60vh;
  padding: 0px 0px 25px 50px;
}

#text-center-div {
  margin: 30px 0;
}

#left-front-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 40vh;
}

#front-left-text-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 30vh;
}

#text1 {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
}


#text2 {
  color: var(--white);
  font-weight: 600;
  font-size: 25px;
}

#text3 {
  color: var(--white);
  font-weight: 700;
  font-size: 34px;
}

#text4 {
  color: var(--white);
  font-size: 24px;
  font-weight: 400;
}

#text5 {
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
}

#thecoder-ai-div {
  border-bottom: 2px solid var(--white);
}

#thecoder-ai-button {
  color: var(--white);
  font-size: 14px;
}

#thecoder-ai-button:hover {
  color: var(--white);
  font-size: 14px;
}

#text5-line {
  color: var(--white);
}

#right-front {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}

#right-front-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 40vh;
  align-self: flex-start;
  padding: 30px;
}

#front-image {
  width: 85%;
  height: 90vh;
}

#front-image-mobile {
  width: 90vw;
  height: 35vh;
}

.get-started-btn {
  border-radius: 3px;
  background-color: var(--light-green);
  border: none;
  padding: 5px 20px;
}

.get-started-btn:hover {
  background-color: var(--light-green);
}

#main-navbar {
  padding: 10px 30px;
  border: 1px solid var(--light-gray);
}

.navbar-dropdown-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.navbar-dropdown-sections-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.dropdown-item-text {
  font-weight: 400;
  font-size: 16px;
}

.dropdown-helping-text {
  font-size: 13px;
  color: var(--gray);
  font-weight: 300;
}

#nav-section1 {
  padding: 20px 30px;
}

#nav-section2 {
  padding: 20px 30px;
  background-color: var(--white);
}

.nav-dropdown-title {
  color: black
}

.nav-title {
  color: black
}

.nav-brand-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;


}

.sub-title {
  font-size: 22px;
}

.designed-bulleted {
  width: 25px;
  height: 25px;
  object-fit: contain;
}


/*--------------------------------------------------------------
# MainScreen Information section start
--------------------------------------------------------------*/

.information-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin: 82px 0;
}

@media screen and (max-width: 600px) {
  .information-sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .information-sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
  }
}

.information-text-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 0 0 0 40px;
}

@media screen and (max-width: 600px) {
  .information-text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 90vw;
    padding: 10px 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .information-text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 90vw;
    padding: 10px 0;
  }
}

#information-text1 {
  font-size: 40px;
  font-weight: 500;
  margin-top: 60px;
  font-family: 'Poppins';
}

@media screen and (max-width: 600px) {
  #information-text1 {
    font-size: 35px;
    font-weight: 500;
    font-family: 'Poppins';
  }
}

#information-text2 {
  /*font-size: 18px;*/
  margin: 40px 0;
  font-family: 'Nunito';
}

#check-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  ;
}

.check-box-text {
  margin: 2px 0 0 10px;
  font-family: 'Nunito';
}

@media screen and (max-width: 600px) {
  .check-box-text {
    margin: 6px 0 0 10px;
    font-family: 'Nunito';
  }
}

.information-image-section {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

@media screen and (max-width: 600px) {
  .information-image-section {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .information-image-section {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

#information-image-style {
  width: 50%;
  height: 50%;
  object-fit: contain;
}

@media screen and (max-width: 600px) {
  #information-image-style {
    width: 50%;
    height: 50%;
  }
}

@media screen and (max-width: 600px) {
  #information-image-style {
    width: 90vw;
    height: 70vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  #information-image-style {
    width: 72vw;
    height: 55vw;
  }
}

#information-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

#get-started-information-btn {
  border-radius: 3px;
  background-color: var(--light-green);
  border: none;
  padding: 15px 25px;
  font-weight: 500;
}

#get-started-information-btn:hover {
  background-color: var(--light-green);
}

.solution-button {
  font-size: 14px;
  color: black;
  font-family: 'Nunito';
}

.solution-button:hover {
  font-size: 14px;
  color: black;
  font-family: 'Nunito';
  padding-top: 5px;
  padding-bottom: 5px;

}

#pricing-solution-div {
  border-bottom: 2px solid black;
  height: 50%;
  margin-left: 20px;
}

#pricing-solution-div:hover {

  color: black !important;
}

/*--------------------------------------------------------------
# MainScreen Information section ends
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# MainScreen Client section Starts
--------------------------------------------------------------*/

#clients {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

#client-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: 30px 0;
}

/*--------------------------------------------------------------
# MainScreen Client section Starts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# MainScreen Feature section Starts
--------------------------------------------------------------*/

#feature {
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainFeatureBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
}

.featureBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 10px 10px 10px var(--light-cyan-blue);
  padding: 50px 50px;
  border: 1px solid var(--light-gray);
  /* width: 35%; */
  margin: 0 10px;
}

.featureBoxText {
  text-align: center;
  color: var(--dim-gray);
  font-weight: 400;
}

/*--------------------------------------------------------------
# MainScreen About section Starts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# MainScreen about section Starts
--------------------------------------------------------------*/

.about {
  padding: 40px 0 0 0;
  background-color: #f8f9fd;
}

.container {
  padding: 60px 50px;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .container {
    padding: 30px;
  }
}

.content h3 {
  font-weight: 400;
  font-size: 32px;
  color: #364146;
}

.content ul {
  list-style: none;
  padding: 0;
}

.content ul li {
  padding-bottom: 10px;
}

.content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--light-green);
}

.content p:last-child {
  margin-bottom: 0;
}

.about-para {
  margin-top: 40px;
  font-size: 18px;
}

.about-title {
  font-weight: 600;
  font-family: 'Poppins';
}

/*--------------------------------------------------------------
# Frequently Asked Questions Section
--------------------------------------------------------------*/
.content h3 {
  font-weight: 400;
  font-size: 34px;
}

.content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.content p {
  font-size: 15px;
  color: #6c757d;
}

.accordion-item {
  border: 0;
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px var(--lighter-gray);
  border-radius: 10px;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.faq-style-wrapper .faq-title {
  border: none !important;
  font-family: 'Poppins';
}

.faq-style-wrapper .faq-body .faq-row {
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px var(--lighter-gray);
  border-radius: 10px;
  border: none !important;
  padding: 8px 20px;
  margin-bottom: 40px;
  margin-top: 15px;
  border: 1px solid green;
  font-family: 'Nunito'
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-family: 'Nunito'
}

.faq-style-wrapper .faq-body .faq-row .row-content .row-content-text {
  font-family: 'Nunito'
}

.accordion-collapse {
  border: 0;
  border: 1px solid red
}

.accordion-button {
  padding: 20px 50px 20px 20px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-default);
  text-align: left;
  background: #fff;
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid red
}

.accordion-button .num {
  padding-right: 10px;
  font-size: 20px;
  line-height: 0;
  color: var(--color-primary);
  border: 1px solid red
}

.accordion-button:not(.collapsed) {
  color: var(--color-primary);
  border-bottom: 0;
  box-shadow: none;
  border: 1px solid red
}

.accordion-button:after {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 1px solid red
}

.accordion-body {
  padding: 0 40px 30px 45px;
  border: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  border: 1px solid red
}


/*--------------------------------------------------------------
# RetailScreen 
--------------------------------------------------------------*/

#retail-information-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
}

#retail-solution-button {
  border-radius: 3px;
  background-color: var(--white);
  border: 2px solid var(--black);
  padding: 15px 25px;
  font-weight: 500;
  font-family: 'Nunito';
}

#retail-solution-button:hover {
  color: white;
  font-family: 'Nunito';
}

#retail-get-started-information-btn {
  border-radius: 3px;
  border: none;
  padding: 18px 35px;
  font-weight: 500;
  margin-left: 20px;
}

#retail-get-started-information-btn:hover {
  background-color: black;
  color: white;
}

.about {
  padding: 20px 0 0 0;
  background-color: #f8f9fd;
}

.container {
  padding: 60px 50px;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .container {
    padding: 30px;
  }
}

.content h3 {
  font-weight: 400;
  font-size: 32px;
  color: #364146;
}

.content ul {
  list-style: none;
  padding: 0;
}

.content ul li {
  padding-bottom: 10px;
}

.content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--light-green);
}

.content p:last-child {
  margin-bottom: 0;
}

.about-para {
  margin-top: 40px;
}

.about-title-retail-screen {
  font-weight: 500;
}

#solution-button-retail-screen {
  color: black;
  border-bottom: 2px solid black;
  font-weight: 500;
  padding: 4px 0;
  font-family: 'Nunito';
}

#solution-button-retail-screen:hover {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  background-color: black;
  border-radius: 5px;
  font-family: 'Nunito';
  font-weight: 500;
}

#pricing-solution-div-retail-screen {
  margin-top: 10px;
}


/*--------------------------------------------------------------
# Case Studies Section
--------------------------------------------------------------*/
.case-studies-section {
  background: rgba(var(--color-secondary-rgb), 0.04);
  margin-top: 60px;
}

.case-studies-container {
  padding: 50px;
}

@media (max-width: 992px) {
  .case-studies-container {
    padding: 10px;
  }
}

.case-studies-header {
  text-align: left;
  padding-bottom: 20px;
}

.case-studies-header h2 {
  font-weight: 600;
  font-size: 40px;
}

.case-studies-box {
  padding: 30px 30px;
  box-shadow: 0 3px 20px -2px rgba(var(--color-gray-rgb), 0.15);
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--black);
  overflow: hidden;
}

.builder-studio-button {
  background-color: var(--white);
  border-radius: 2px;
  border: 1px solid var(--light-gray);
  font-size: 13px;
  font-weight: 500;
  padding: 4px 10px;
}

.case-studies-title-div {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.case-studies-image {
  height: 60px;
  width: 60px;
}

.case-study-btn-div {
  margin-top: 40px;
}

.view-more-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.view-more-btn {
  background-color: var(--light-green);
  padding: 10px 40px;
  border-radius: 3px;
  color: var(--black)
}

/* Transform Your Business */

#transform-section-main-heading {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 30px;
}

.section2 {
  padding: 0 40px 0 30px;
}

.form {
  width: 100%;
  border: 2px solid var(--light-gray);
  border-bottom: none !important;
  border-width: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: start;
}

.application {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.first-name {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.first-name-label {
  font-size: 13px;
  font-weight: 400;
  color: var(--gray);
}

.first-name-input {
  padding: 6px 15px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  font-size: 13px;
  width: 47%;
}

.email-phonenumber {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.email {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.phone-number {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.email-phone-input {
  padding: 6px 15px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  font-size: 13px;
  width: 95%;
}

.textarea {
  width: 98%;
  height: 60%;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 6px 15px;
  font-size: 13px;
}

.join-question {
  height: 30vh;
  margin-top: 14px
}

.ul-of-experience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5px 0;
}

.experience-label {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray);
}

.experience-radio {
  margin-right: 10px;
}

.apply-btn {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 20px 10px;
  color: #000000;
}



.job-description-textinput {
  border: 1px solid var(--light-gray);
}

.jobdescription-textarea {
  width: 98%;
  height: 60%;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 6px 15px;
  font-size: 13px;
}

/* Software-section */

.software-section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 45px;
}

.software-text-div {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 40px 40px;
}

.software-text-div h2 {
  color: var(--white);
  font-weight: 600;
}

.software-text-div h4 {
  color: var(--white)
}

.software-image {
  height: 42vh;
  width: 35vw;
}

/*--------------------------------------------------------------
# CustomerScreen Customer section start
--------------------------------------------------------------*/

.customer-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 60vh;
}

.customer-sections-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.customer-text-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50vw;
  padding: 0 0 0 40px;
}

.customer-text-section-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 90vw;
  padding: 80px 0;
}

#customer-text1 {
  font-size: 50px;
  font-weight: 600;
}

#customer-text1-mobile {
  font-size: 40px;
  font-weight: 600;
}

#customer-text2 {
  font-size: 18px;
  margin: 40px 0;
}

#check-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-box {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  ;
}

.customer-image-section {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-image-section-mobile {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

#customer-image-style {
  width: 80%;
  height: 80%;
}

#customer-image-style-mobile {
  width: 90vw;
  height: 45vh;
}

#customer-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

#get-started-customer-btn {
  border-radius: 3px;
  background-color: var(--light-green);
  border: none;
  padding: 15px 25px;
  font-weight: 500;
}

#get-started-customer-btn:hover {
  background-color: var(--light-green);
}

#solution-button {
  font-size: 14px;
  color: black;
  font-family: 'Nunito';
}

#solution-button:hover {
  font-size: 14px;
  color: black;
  font-family: 'Nunito';
}

#explore-stories-div {
  border-bottom: 2px solid var(--light-green);
  height: 50%;
}

#explore-stories-div:hover {
  background-color: var(--light-green);
}

/* CustomerScreen Case Studies */
.customer-case-studies-section {
  background: rgba(var(--color-secondary-rgb), 0.04);
  padding: 50px 0;
}

.customer-case-studies-container {
  padding: 50px;
}

@media (max-width: 992px) {
  .customer-case-studies-container {
    padding: 10px;
  }
}

.customer-case-studies-header {
  text-align: left;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.quick-search-selections {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.quick-search-selection-div {
  margin: 0 20px;
  padding: 10px;
}

.customer-case-studies-header h2 {
  font-weight: 600;
  font-size: 40px;
}

.customer-case-studies-box {
  padding: 30px 30px;
  box-shadow: 0 3px 20px -2px rgba(var(--color-gray-rgb), 0.15);
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.quick-search-headline-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 20px 0 40px 0
}

.quick-search-headline-captions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.caption-text {
  padding: 3px 8px;
  border: 1px solid var(--light-gray);
  border-radius: 14px;
  margin: 5px;
  font-size: 12px;
  color: var(--black);
  font-weight: 300;
}

.quick-search-image {
  height: 100px;
  width: 140px;
  border: 2px solid var(--light-green);
  margin: 20px
}

.customer-case-study-btn-div {
  margin-top: 40px;
}

.customer-view-more-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.customer-view-more-btn {
  background-color: var(--light-green);
  padding: 10px 40px;
  border-radius: 3px;
  color: var(--black)
}

#read-full-story-div {
  margin-top: 40px;
}

#read-full-story-button {
  color: black;
  border-bottom: 2px solid var(--light-green);
  font-weight: 500;
  padding: 4px 0;
}

#read-full-story-button:hover {
  color: black;
  background-color: var(--light-green);
  font-weight: 500;
}

/* Customer Feedback Screen */
.customer-feedback-section {
  background: var(--violet);
  padding: 50px 0;
}

.customer-feedback-container {
  padding: 50px;
}

@media (max-width: 992px) {
  .customer-feedback-container {
    padding: 10px;
  }
}

.customer-feedback-header {
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid blcak;
}

.customer-feedback-header h2 {
  font-weight: 600;
  font-size: 40px;
  color: var(--white);
}

.customer-feedback-box {
  padding: 30px 30px;
  box-shadow: 0 3px 20px -2px rgba(var(--color-gray-rgb), 0.15);
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.with-styles {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 10px;
  margin: 0 5px;
  padding: 30px 50px;
  height: 60vh;
}

.carousel-top-div {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}

.carousel-img {
  height: 60px;
  width: 60px;
  border-radius: 50px;
}

.carousel-top-texts {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
}

.carousel-top-text1 {
  font-size: 16px;
  font-weight: 800;
}

.carousel-top-text2 {
  font-size: 12px;
  font-weight: 300;
  color: var(--gray);
}

.carousel-content-text1 {
  font-size: 16px;
  font-weight: 800;
  margin: 10px 0 0 0;
  line-height: 30px;
}

.carousel-content-text2 {
  font-size: 16px;
  margin: 5px 0;
  font-style: italic;
}

.carousel-content-text3 {
  font-size: 16px;
  line-height: 30px;
}

/* demo section */
.demo {
  width: 100%;
  height: 40vh;
  background-size: cover;
  position: relative;
}

.demo h2 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  color: var(--violet);
}

.demo h2 span {
  position: relative;
  z-index: 1;
  padding: 0 5px;
  display: inline-block;
}

.demo p {
  margin-bottom: 30px;
  font-size: 16px;
  padding: 0 30px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .demo h2 {
    font-size: 32px;
  }
}


/* Award Winning Section */

.award-winning-section {
  background-color: #2C2E3A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 60vh; */
  padding: 40px;
}

.award-inner-div {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 40px;
  padding: 40px 50px;
  border: 1px solid slateblue;
}

.award-text-div1 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px 10px;
}

.award-text-div1 h6 {
  font-weight: 700;
}

.award-text-div1 h2 {
  color: var(--violet);
  font-weight: 600;
}

.award-text-div2 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 40%;
}

.award-text-div2 p {
  font-weight: 500;
  color: var(--dim-gray);
}

.award-top-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

#award-img1 {
  height: 130px;
  width: 130px;
  border-radius: 10px;
}

#award-img2 {
  height: 130px;
  width: 130px;
  border-radius: 50px;
}

.award-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.award-texts h4 {
  color: var(--white);
  text-align: center;
}

.award-texts h6 {
  color: var(--white);
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
}

/*--------------------------------------------------------------
# CustomerScreen Customer section ends
--------------------------------------------------------------*/


.get-btn {
  display: inline-block;
  padding: 0.6rem 1.6rem;
  font-size: 16px;

  color: black;
  border: 1px solid black;
  background-color: #F9F9FA;
  cursor: pointer;
  position: relative;

  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  font-family: 'Nunito';
}

.get-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  transform: translateX(-100%);
  transition: all ease-out .3s;
  z-index: -1;
  font-family: 'Nunito';
}

.get-btn:hover::before {
  transform: translateX(0);
  color: white;
  font-family: 'Nunito';
}

.footer-links {
  font-size: 14px;
}


#company-name-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* message form */

@media screen and (max-width: 600px) {
  .form-heading-div {
    margin: 0 0.5rem
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .form-heading-div {
    margin: 0 2rem
  }
}

@media screen and (max-width: 600px) {
  .form-main-div {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .form-main-div {
    margin: 0 1.5rem;
    margin-bottom: 4rem;
  }
}

/* end of css  */



.apply-button {
  padding: 5px 40px;
  margin-top: 5px;
  border: unset;
  border-radius: 5px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  transition: all 250ms;
  overflow: hidden;
}

.apply-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 5px;
  background-color: #212121;
  z-index: -1;

  transition: all 250ms
}

.apply-button:hover {
  color: #e8e8e8;
}

.apply-button:hover::before {
  width: 100%;
}

.multi-carousel-div {
  padding: 30px 0 0 40px;
}

.blog-carousel-div {
  /*padding: 20px 0px;*/
  /*background-color: #e3d7f8;*/
}

.blog-heading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------------------------------
# HomeScreen Feature section starts
--------------------------------------------------------------*/

.feature-list-div {
  margin-top: 10px;
  margin-bottom: 40px;
}

.feature-heading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 100px;
}

.feature-heading {
  font-size: 28px;
  width: 90%;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
  text-align: center;
}

.about-feature {
  font-size: 20px;
  width: 90%;
  line-height: 1.4;
  text-align: center;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .feature-heading {
    font-size: 32px;
    width: 50vw;
    /* change to 50% of viewport width */
    line-height: 38px;
  }

  .about-feature {
    font-size: 28px;
    width: 60vw;
    /* change to 60% of viewport width */
  }
}


/*--------------------------------------------------------------
# HomeScreen Feature section ends
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# HomeScreen LaptopMobile section starts
--------------------------------------------------------------*/

.laptop-mobile-section {
  background-color: rgb(247, 248, 253);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 15px 0;
  flex-direction: column;
}

.laptop-heading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 40px 0;
}

.laptop-heading {
  font-size: 28px;
  width: 90%;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
  text-align: center;
}

.about-laptop {
  font-size: 20px;
  width: 90%;
  line-height: 1.4;
  text-align: center;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .laptop-heading {
    font-size: 32px;
    width: 50vw;
    /* change to 50% of viewport width */
    line-height: 38px;
  }

  .about-laptop {
    font-size: 28px;
    width: 60vw;
    /* change to 60% of viewport width */
  }
}


.laptop-mobile-img {
  width: 65vw;
  height: 43vw;
  align-self: center;
}

/*--------------------------------------------------------------
# HomeScreen LaptopMobile section ends
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# HomeScreen HomeDesign section starts
--------------------------------------------------------------*/

.home-list-div {
  margin-top: 100px;
  margin-bottom: 40px
}

.home-heading-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px
}

.home-heading {
  font-size: 28px;
  width: 90%;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
  text-align: center;
}

.about-home {
  font-size: 20px;
  width: 90%;
  line-height: 1.4;
  text-align: center;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .home-heading {
    font-size: 32px;
    width: 50vw;
    /* change to 50% of viewport width */
    line-height: 38px;
  }

  .about-home {
    font-size: 28px;
    width: 60vw;
    /* change to 60% of viewport width */
  }
}

/*--------------------------------------------------------------
# HomeScreen HomeDesign section ends
--------------------------------------------------------------*/

iframe {
  height: 600px
}



/*--------------------------------------------------------------
# IframeScreen | apps sectiosn starts
--------------------------------------------------------------*/
.apps-mobile-section {
  background-color: rgb(247, 248, 253);
  padding-top: 50px;
}

.apps-heading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px
}

.apps-heading {
  font-size: 28px;
  width: 90%;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
  text-align: center;
}

.about-apps {
  font-size: 20px;
  width: 90%;
  line-height: 1.4;
  text-align: center;
  align-self: center;
}

.share-button {
  /*font-size: 14px;*/
  color: #888;
  font-family: 'Nunito';
}

.share-button:hover {
  /*font-size: 14px;*/
  color: black;
  font-family: 'Nunito';
  /*padding-top: 5px;*/
  /*padding-bottom: 5px;*/

}

.iframe-main-div {
  height: 100vh !important;
}

.iframe-design {
  height: 100vh !important;
  width: 100% !important
}

/*--------------------------------------------------------------
# IframeScreen ends
--------------------------------------------------------------*/

.demo-video-div {
  margin-top: 74px;
  margin-bottom: 12px;
}