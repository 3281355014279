.nav-get-btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 16px;
  
    color: black;
    border: 1px solid black;
    background-color: #F9F9FA;
    cursor: pointer;
    position: relative;
  
    text-decoration: none;
    overflow: hidden;
    z-index: 1;
    font-family: 'Nunito';
  }
  
  .nav-get-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: white;
    background-color: black;
    transform: translateX(-100%);
    transition: all ease-out .3s;
    z-index: -1;
    font-family: 'Nunito';
  }
  
  .nav-get-btn:hover::before {
    transform: translateX(0);
    color: white;
    font-family: 'Nunito';
  }