/* .footer {
    font-size: 14px;
}

.footer .footer-content {
    padding: 0px 0 0px 0;
    margin: 0px !important;
    background-color: #f5f5f5 !important;
}

.foot-logo {
    height: 80px;
    width: 120px;

}

.footer .footer-content .footer-info h3 span {
    color: #000;
}

.footer .footer-content .footer-info p {
    font-size: 14px;
    margin-bottom: 0;
    color: #000;

}

.footer .footer-content .footer-info p:hover {
    color: green;
}

.footer .footer-content h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    position: relative;
    margin-bottom: 0.65rem;
}

.footer .footer-content .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-content .footer-links ul i {
    color: #000 !important;
    font-size: 12px;
}

.footer .footer-content .footer-links ul li {
    padding: 7px 0;
    display: flex;
    align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-content .footer-links ul a {
    color: #808080 !important;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
    color: var(--color-white);
}

.footer .footer-content .footer-newsletter form {
    margin-top: 30px;
    background: white;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
}

.footer .footer-content .footer-newsletter form input[type="email"]:focus-visible {
    outline: none;

}

.footer .footer-content .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    background: #333333;
    color: white !important;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

.footer .footer-content .footer-newsletter p {
    color: white !important;
}

.footer .footer-content .footer-newsletter p:hover {
    color: #f5f5f5;
}

.footer .footer-content .footer-newsletter form input[type="submit"]:hover {
    background: #000;
    color: white;
}

.footer .footer-legal {
    padding: 30px 0;
    background-color: #1a2a5a !important;
}

.footer .footer-legal .credits {
    padding-top: 4px;
    font-size: 13px;
    color: var(--color-white);
}

.footer .footer-legal .credits a {
    color: var(--color-primary-light);

}


.social-icons {
    margin: 0px 5px;
    color: black;
}

.firm-name {
    font-size: 15px !important;
    color: red;
}

#company-name-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.email-submission-alert {
    color: black !important;
    padding: 5px;
}

.footer address {
    color: grey;
    font-size: 11px;
    margin: 5px 0 !important;
    font-family: 'Nunito';
}

.footer-logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.footer-img {
    height: 5em;
}

.footer-logo {
    font-family: 'nunito', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: 'black';
    margin: 0 !important;
}

.technology-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-name-div {
    display: 'flex';
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
}

.tools-title {
    font-weight: 400 !important;
    font-size: 15px !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
}

.privacy-div {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 767px) {
    .footer-logo-div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
    }

    .tools-title {
        font-weight: 400 !important;
        font-size: 15px !important;
        margin-top: 15px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
    }
}

.email-form {
    display: flex;
    justify-content: space-between;
}

.form-group {
    width: 100%;
    display: flex;
}

.form-control {
    flex: 1;
    margin-right: 10px;
}

.btn {
    flex: 0 0 auto;
}

.limited-para {
    color: #808080 !important;
} */

/* .footer {
    display: flex;
    flex-flow: wrap;
    font-size: 0.75em;
    padding: 2em 0 0.5em 0;
    color: #2f2f2f;
    background-color: whitesmoke;
    border-top: 1px solid lightgrey;
}

@media screen and (min-width: 767px) {
    .footer-address {
        flex: 1;
    }
}

.footer-address {
    margin-right: 1.25em;
    margin-bottom: 2em;
    margin-top: 1.25em;
    padding-left: 2em;
}

@media screen and (min-width: 767px) {
    .footer-nav {
        flex: 2;
    }
}

.footer-nav {
    display: flex;
    flex-flow: wrap;
    padding-right: 2em;
    padding-left: 3em;
}

.legal {
    display: flex;
    flex-wrap: wrap;
    color: grey;
    margin-top: 1em;
    padding: 0.5em 2em 0 2em;
    align-items: center;
}

.footer-logo-div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footer-img {
    height: 5em;
    margin: 0 0.5em 0 0;
    padding: 0 0 0.15em 0;
}

.footer-logo {
    font-family: 'nunito', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
} */

/*---------------------------------------footer style starts---------------------*/
footer {
    flex: 0 80%;
}

.footer {
    display: flex;
    flex-flow: wrap;
    font-size: 0.75em;
    padding: 2em 5rem 0.5em 5rem;
    color: #2f2f2f;
    background-color: whitesmoke;
    border-top: 1px solid lightgrey;
}

@media screen and (max-width: 767px) {

    .footer {
        display: flex;
        flex-flow: wrap;
        font-size: 0.75em;
        padding: 2em 0 0.5em 0;
        color: #2f2f2f;
        background-color: whitesmoke;
        border-top: 1px solid lightgrey;
    }

    .footer-nav {
        flex-direction: column;
    }

    .nav-item {
        margin-bottom: 2em;
        padding: 1rem;
    }
}


.footer>* {
    flex: 1 80%;
}

.footer-address {
    margin-right: 1.25em;
    margin-bottom: 2em;
    margin-top: 1.25em;
    padding-left: 2em;
}

.footer-logo-div {
    display: flex;
    align-items: center !important;
    cursor: pointer;
}

.footer-img {
    height: 5em;
    margin: 0 0.5em 0 0;
    padding: 0 0 0.15em 0;
}

.footer-logo {
    font-family: 'nunito', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
}

.footer-logo span {
    color: rgba(0, 0, 0, 0.2)
}

.footer-address h1 {
    display: inline;
    margin: 0 !important;
    padding: 0 !important;
}

.nav-title {
    font-weight: 400;
    font-size: 1.25em;
}

.nav-title a {
    color: black;
}

.your-technology {
    font-style: normal;
    color: grey;
    font-size: 0.95em;
    margin-top: 4px;
    /* margin: 0 !important;
  padding: 0 !important; */
}

.footer a {
    text-decoration: none !important;
}

.footer ul {
    list-style: none;
}

.footer li {
    line-height: 2em;
    /* padding-left: 1em; */
    /* border: 1px solid aqua; */
    padding: 1 !important;
}

.footer-nav {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    flex: 2;
    /* border: 1px solid aqua; */
}

.footer-nav>* {
    flex: 1 50%;
    margin-right: 1.25em;
}

.nav-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-ul li {
    padding: 2px 0;
}


.nav-ul a {
    color: #808080 !important;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    margin-left: 0;
    /* Reset the default margin-left */
}

.nav-ul-extra {
    column-count: 2;
    column-gap: 1.25em;
}

.legal {
    display: flex;
    flex-wrap: wrap;
    color: grey;
    /* border-top: 1px solid lightgrey; */
    margin-top: 1em;
    padding: 0.5em 2em 0 2em;
    align-items: center;
}

.legal-links {
    display: flex;
    align-items: center;
}

.legal-links a:hover {
    color: grey;
    text-decoration: none !important;
}

.legal-links a {
    color: grey;
}

.legal-links ul {
    display: flex;
    gap: 1em;
}

.legal .legal-links {
    margin-left: auto;
}

@media screen and (min-width: 767px) {
    .footer-nav>* {
        flex: 1;
    }

    .nav-item-extra {
        flex: 2 1;
    }

    .footer-address {
        flex: 1;
    }

    .footer-nav {
        flex: 2;
    }

    .footer li {
        padding-left: 0;
    }
}

.limited-para {
    color: #808080 !important;
}

.email-form {
    display: flex;
    justify-content: space-between;
}

.form-group {
    width: 100%;
    display: flex;
}

.form-control {
    flex: 1;
    margin-right: 10px;
    width: 20vw
}

@media screen and (max-width: 767px) {

    .form-control {
        flex: 1;
        margin-right: 10px;
    }
}


.btn {
    flex: 0 0 auto;
}

/*---------------------------------------footer style ends-----------------------------*/