.carousel-container {
    padding: 0 0 40px 0;
}


.card-box {
    display: flex;
    flex-direction: row;
    
}

.card-body {
    padding: 0 !important;
    align-items: flex-start;
    margin: 0 !important
}

.card-title {
    left: 0;
}

.card-img {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.card-button {
    position: absolute;
    /* color: #6c757d;
    border-radius: 20px; */
}

/* .card-button:hover {
    color: black;
    background-color: gray;
    border-radius: 100%;
} */

.card-date {
    position: absolute;
    bottom: 23px;
    left: 40px;
    font-size: 0.8rem;
    color: #666;
}

.card-description {
    color: #666;
    font-family: "Nunito", sans-serif;
}

.card-icon {
    bottom: 23px;
    color: #6c757d;
    font-size: 1.2rem;
    position: absolute;
    left: 10px;
}

.card-div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-item {
    width: 22vw;
    height: 24.5rem !important;
}

.image-container {
    width: 100%;
    height: 200px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    align-self: center;
}

@media only screen and (max-width: 600px) {
    .card-item {
        width: 90vw;
        height: 24rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .image-container img {
        width: 100%;
        object-fit: cover;
        align-self: center;
    }
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
    .card-item {
        width: 45vw;
        height: 27rem;
    }
}

@media only screen and (min-width: 900px) {
    .card-item {
        width: 22vw;
        height: 27rem;
    }
} 

.card-text-div {
    padding: 10px 10px 0 10px !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}

.card-img-top {
    border-radius: none !important;
}

.circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(192, 192, 192, 0.5);
    height: 25px; 
    width: 25px;
    border-radius: 50%; /* make the wrapper a circle */
    overflow: hidden; /* clip the image to the shape of the circle */
    position: absolute;
  }
  
  .circle {
    position: relative;
    height: 100%; /* set the height and width of the circle to be smaller than the wrapper */
    width: 100%;
    border-radius: 50%;
    padding: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .circle img {
    padding: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; /* center the image within the circle */
    height: 100%;
    width: 100%;
    transition: background-color 0.2s ease-in-out; /* add a transition effect for the background color change */
  }
  
  .circle:hover {
    background-color: rgba(51, 51, 51, 0.5); /* change the background color on hover */
  }