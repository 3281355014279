/* .card-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.card-body {
    padding: 0 !important;
    margin-top: 10px;
}

.card-title {
    left: 0 !important;
    padding: 0 !important;
    margin-top: 5px !important;
}

.card-img {
    height: 200px;
}

.card-button {
    position: absolute;
    bottom: 12px;
    right: 10px;
    border-radius: 20px !important;
    margin-top: 0.5rem;
}

.card-date {
    position: absolute;
    bottom: 23px;
    left: 40px;
    font-size: 0.9rem;
    margin: 0;
    color: #666;
}

.card-descp {
    color: #666;
    padding-left: 0px !important;
}

.card-icon {
    bottom: 23px !important;
    color: #6c757d;
    font-size: 1.2rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 10px !important;
} */

.carousel-container {
    padding: 0;
  }
  
  .carousel-container-mobile {
    padding: 0 10px;
  }
  
  .card-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .card-body {
    padding: 0 !important;
    margin-top: 10px;
  }
  
  .card-title {
    left: 0 !important;
    padding: 0 !important;
    margin-top: 5px !important;
  }
  
  .card-img {
    height: 200px;
  }
  
  .card-button {
    position: absolute;
    bottom: 12px;
    right: 10px;
    border-radius: 20px !important;
    margin-top: 0.5rem;
  }
  
  .card-date {
    position: absolute;
    bottom: 23px;
    left: 40px;
    font-size: 0.9rem;
    margin: 0;
    color: #666;
  }
  
  .card-descp {
    color: #666;
    padding-left: 0px !important;
  }
  
  .card-icon {
    bottom: 23px !important;
    color: #6c757d;
    font-size: 1.2rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 10px !important;
  }