.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-item {
  flex-basis: calc(25% - 50px);
  text-align: center;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  .feature-item {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .feature-item {
    flex-basis: 50%;
  }
}

.feature-name {
  font-weight: 600;
  margin-bottom: 5px;
}

.feature-icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}