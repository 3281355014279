.poppins-font {
    font-family: 'Poppins';
}

.nunito-font {
    font-family: 'Nunito';
}

.card-image {
    object-fit: cover;
    height: 200px;
    width: 18rem;
}

.card-blog {
    margin: 10px;
}

.blog-main-section {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.blog-heading {
    text-align: center;
    margin-top: 2rem;
    line-height: 1.6;
    font-family: 'Poppins';
}

.blog-sub-heading {
    text-align: center;
    line-height: 1.6;
    padding-bottom: 2rem;
    font-family: 'Nunito';
    width: 60%;
    margin: auto;
    font-weight: 100;
}

.blog-image {
    object-fit: cover;
    height: 500px;
    width: 100%;
}

.blog-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-back-margin {
    margin-top: 40px;
}

.blog-gap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
}

.blogger-image {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 100%;
}

.blogger-image-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogger-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blogger-name {
    font-size: 14px;
    text-align: center;
    font-weight: 100;
}

.blog-description {
    line-height: 1.6;
}

.topic-heading {
    line-height: 1.6;
    font-weight: 700;
    margin-top: 10px;
}

.blog-explanation {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 0rem;
    padding-bottom: 3rem;

}

.topic-subheading {
    line-height: 1.6;
    font-weight: 500;
}

.topic-images {
  object-fit: contain;
  max-height:650px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.topic-iframe {
  object-fit: contain;
  max-height:90vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}


@media screen and (max-width: 768px) {

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 12px;
    }

    p {
        font-size: 12px;
    }

    .blog-explanation {
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 0rem;
        padding-bottom: 0.5rem;
    }

    .blog-image {
        height: 150px;
        width: 100%;
    }

    .blog-back {
        margin-top: 10px;
    }

    .blog-gap {
        margin-bottom: 40px;
    }
    .topic-iframe {
      object-fit: contain;
      max-height:30vh;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

}

.image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.click {
    cursor: pointer;
}

.grey {
    color: #bbb;
}

.grey:hover {
    color: #777;
}

.link {
    color: dodgerblue;
}

.link:hover {
    color: #8A2BE2;
}