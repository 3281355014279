.django-backend-feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.django-backend-feature-item {
  flex-basis: calc(100% - 20px);
  margin: 12px 6px;
}

@media screen and (min-width: 768px) {
  .django-backend-feature-item {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (min-width: 992px) {
  .django-backend-feature-item {
    flex-basis: calc(32% - 40px);
  }
}

.django-backend-feature-image-div {
  margin-bottom: 10px;
  max-height: 275px; /* or any other value you want to set */
  overflow: hidden;
}

.django-backend-feature-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  object-fit: cover;
}

.django-backend-feature-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.django-backend-description {
  color: #888;
  font-size: 15px;
}
